var serviceModule = angular.module('t2g.service', [
    'gettext',
    'ui.router',
    require('t2g.common.services.modalService').name,
    require('t2g.common.services.notificationService').name

]);

module.exports = serviceModule;

serviceModule.controller('ServiceGroupModalCtrl', require('./modal/serviceGroupModalCtrl'));
serviceModule.controller('ServiceListCtrl', require('./list/listCtrl'));
serviceModule.controller('ServiceModalCtrl', require('./modal/ServiceModalCtrl'));
serviceModule.controller('ServiceLocationModalCtrl', require('./modal/ServiceLocationModalCtrl'));
serviceModule.controller('ServiceResourceMatchController', require('./serviceResourceMatch/ServiceResourceMatchCtrl'));

serviceModule.config(function($stateProvider) {
    $stateProvider
        .state('private.main.services', {
            url: '/services',
            title: 'Services',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/service/list/ServiceListView.html?v=3.81.1',
            controller: 'ServiceListCtrl',
            resolve: {
                officeResolve: function(OfficeDA) {
                    return OfficeDA.get();
                }
            }
        })
        .state('private.main.serviceResourceMatch', {
            url: '/serviceResourceMatch',
            title: 'Services',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/service/serviceResourceMatch/ServiceResourceMatchView.html?v=3.81.1',
            controller: 'ServiceResourceMatchController as ServiceResourceMatchController',
            resolve: {
                officeResolve: function(OfficeDA) {
                    return OfficeDA.get();
                },
                resourceResolve: function(ResourceDA) {
                    return ResourceDA
                      .list()
                      .then(function (response) {
                          return response.resources;
                      });
                }
            }
        });
});
