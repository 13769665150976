var marketingModule = angular.module('t2g.marketing', [
  'gettext',
  'ui.router',
  require('t2g.common.services.notificationService').name

]);

module.exports = marketingModule;

marketingModule.controller('CampaignListCtrl', require('./CampaignListCtrl'));
marketingModule.controller('NewCampaignCtrl', require('./NewCampaignCtrl'));
marketingModule.controller('NewAutomatedCampaignCtrl', require('./NewAutomatedCampaignCtrl'));
marketingModule.controller('NewCampaignCtrl', require('./NewCampaignCtrl'));

marketingModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.marketing', {
      url: '/marketing/campaigns',
      title: 'Campaigns',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/marketing/CampaignListView.html?v=3.81.1',
      controller: 'CampaignListCtrl as CampaignListCtrl',
      resolve: {
        campaigns(MarketingDA) {
          return MarketingDA.listCampaigns();
        }
      }
    })
    .state('private.main.new-campaign', {
      url: '/marketing/campaigns/new',
      title: 'New Campaign',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/marketing/NewCampaignView.html?v=3.81.1',
      controller: 'NewCampaignCtrl as NewCampaignCtrl'
    })
    .state('private.main.automated-campaign', {
      url: '/marketing/campaigns/automated/:type/:id',
      title: 'New Campaign',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/marketing/NewAutomatedCampaignView.html?v=3.81.1',
      controller: 'NewAutomatedCampaignCtrl as NewAutomatedCampaignCtrl',
      resolve: {
        campaign(gettextCatalog, $stateParams, MarketingDA) {
          const defaultCampaign = {
            type: 'birthday',
            name: gettextCatalog.getString('Birthday Reminder'), // Geburtstagserinnerungen,
            medium: {
              email: {
                subject: gettextCatalog.getString('Happy Birthday {firstname} {lastname}'),
                content: ''
              }
            }
          };

          if ($stateParams.id === 'new') {
            return defaultCampaign;
          } else {
            return MarketingDA
              .listCampaigns($stateParams.id)
              .then((response) => {
                console.log(response);
                return response && Array.isArray(response) && response.length ? response[0] : undefined
              });
          }
        }
      }
    })
    .state('private.main.new-regular-campaign', {
      url: '/marketing/campaigns/new/regular-newsletter',
      title: 'New Campaign',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/marketing/NewRegularCampaignView.html.html?v=3.81.1',
      controller: 'NewARegularCampaignCtrl as NewARegularCampaignCtrl'
    });
});
