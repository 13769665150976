'use strict';

var googleSyncModule = angular.module('t2g.googleSync', [
    'gettext',
    'ui.router',
    require('t2g.common.data-access.google-sync').name,
    require('t2g.common.services.notificationService').name,
    require('t2g.common.services.modalService').name
]);

module.exports = googleSyncModule;

googleSyncModule.controller('GoogleSyncCtrl', require('./GoogleSyncCtrl'));

googleSyncModule.config(function ($stateProvider) {
    $stateProvider
      .state('private.main.googleSync', {
          url: '/google-sync',
          title: 'Google Sync',
          templateUrl: 'https://cdn.termin2go.com/app/app/modules/googleSync/GoogleSyncView.html?v=3.81.1',
          controller: 'GoogleSyncCtrl as GoogleSyncCtrl',
          resolve: {
              googleSync: function (GoogleSyncDA) {
                  return GoogleSyncDA.getCalendarList();
              },
              resources: function (ResourceDA) {
                  return ResourceDA
                    .list()
                    .then(function (response) {
                        return response.resources;
                    });
              }
          }
      });
});

