var angularModule = angular.module('t2g.common.services.modalService', []);

module.exports = angularModule;

angularModule.factory('ServiceGroupModal', function ($compile, $rootScope, $http, $templateCache) {
  var ServiceGroupModal = new Object();
  var scope = $rootScope.$new();
  var body = $(document).find('body');
  var modalHTML = "";


  /**
   * load the modal template file
   */
  $http
    .get('https://cdn.termin2go.com/app/app/modules/service/modal/ServiceGroupModal.html?v=3.81.1')
    .then(function (response) {
      $templateCache.put('ServiceGroupModal.html', response.data);
    });


  /**
   * show the modal
   */
  ServiceGroupModal.open = function () {
    modalHTML = $compile($templateCache.get('ServiceGroupModal.html'))(scope);
    body.append(modalHTML);

    $('#serviceGroupModal').modal({
        show: true,
        backdrop: 'static',
        keyboard: true
      }
    );
  };


  /**
   * hide the modal and clear the current model
   */
  ServiceGroupModal.close = function () {
    $('#serviceGroupModal').modal('hide');

    // remove modal html
    angular.element($('#serviceGroupModal')).scope().$destroy();
    modalHTML.remove();
    $('.modal-backdrop').remove();
    $('body').removeAttr('class');
  };

  return ServiceGroupModal;
});

angularModule.factory('CourseModal', function ($compile, $rootScope, $http, $templateCache) {
  var CourseModal = new Object();
  var scope = $rootScope.$new();
  var body = $(document).find('body');
  var modalHTML = "";


  /**
   * load the modal template file
   */
  $http
    .get('https://cdn.termin2go.com/app/app/modules/course/modal/CourseModal.html?v=3.81.1')
    .then(function (response) {
      $templateCache.put('CourseModal.html', response.data);
    });


  /**
   * show the modal
   */
  CourseModal.open = function (course) {
    modalHTML = $compile($templateCache.get('CourseModal.html'))(scope);
    modalHTML.controller().setCurrentItem(course);
    body.append(modalHTML);

    $('#courseModal').modal({
        show: true,
        backdrop: 'static',
        keyboard: true
      }
    );
  };


  /**
   * hide the modal and clear the current model
   */
  CourseModal.close = function () {
    $('#courseModal').modal('hide');

    // remove modal html
    angular.element($('#courseModal')).scope().$destroy();
    modalHTML.remove();
    $('.modal-backdrop').remove();
    $('body').removeAttr('class');
  };

  return CourseModal;
});
