'use strict';

var userModule = angular.module('t2g.user', [
  'gettext',
  'ui.router',
  require('t2g.common.services.notificationService').name
]);

module.exports = userModule;

userModule.controller('UserCtrl', require('./UserCtrl'));
userModule.controller('UserModalCtrl', require('./UserModalCtrl'));

userModule.config(function ($stateProvider) {
  $stateProvider
    .state('private.main.user', {
      url: '/user',
      title: 'User',
      templateUrl: 'https://cdn.termin2go.com/app/app/modules/user/UserView.html?v=3.81.1',
      controller: 'UserCtrl',
      resolve: {
        userResolve: function (UserDA) {
          return UserDA.list();
        }
      }
    })
    .state('private.main.branch', {
      url: '/branch/:id',
      template: '',
      resolve: {
        permissions: function ($q, $state, $stateParams, UserDA) {
          var deferred = $q.defer();

          UserDA.selectOffice($stateParams.id)
            .then(() => {
              deferred.reject();
              if ($state.current.name === 'private.main.calendar' && $stateParams.resource !== 'all-employees') {
                $state.go('private.main.calendar', {resource: 'all-employees'});
              } else {
                $state.reload();
              }
            });

          return deferred.promise;
        }
      }
    });
});
