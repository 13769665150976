'use strict';

var settingsModule = angular.module('t2g.common.settings', []);

module.exports = settingsModule;

settingsModule.constant('settings', {
  titlePrefix: 'Termin2go',
  titleSeperator: '»',
  apiBase: 'https://app.termin2go.com',
  mandant: 'termin2go',
  modules: {
    invoice: false
  },
  billwerk: {
    key: '58d52859675ecb0e6885380b',
    libUrl: 'https://app.billwerk.com/selfService/billwerkjS',
    products: {
      chf: {
        basic: '58eb818e796508112c52bc9c',
        premium_monthly: '58eb7ec9675ecb0e607526bd',
        premium_yearly: '58eb7eff796508112c52b85c',
        plus_monthly: '5e9d5ab04802000560513b0d',
        plus_yearly: '5e9d5aeb48020222fc44fbd7',
        single_monthly: '602ba18e085e3379cb159d99',
        single_yearly: '602ba20ef63129d5dbb7a120',
        enterprise_monthly: '602ba2bb085e3379cb161038',
        enterprise_yearly: '602ba2ff085e3379cb162a84',
        branch_monthly: '5ea5e83248020228e09cc037',
        branch_yearly: '5ea5e83248020228e09cc037',
        employee: '58eb81c9675ecb0e60752ba3',
        sms: '58f6841614a9ff1434b12755',
        schnelltest_monthly: '',
        schnelltest_yearly: '',
        schnelltestBescheinigung: '',
        schnelltest2_monthly: '',
        schnelltest2_yearly: '',
        schnelltest2Bescheinigung: '',
        schnelltest_archiv: ''
      },
      eur: {
        basic: '58eb829c796508112c52be28',
        premium_monthly: '58eb824b675ecb0e60752c37',
        premium_yearly: '58eb8282796508112c52be05',
        plus_monthly: '5e9d5ba348020222fc44fe6c',
        plus_yearly: '5e9d5bc448020217d04fd9b2',
        single_monthly: '602ba4493958e84566a8c221',
        single_yearly: '602ba46a085e3379cb168457',
        enterprise_monthly: '602ba4bc3958e84566a8c90d',
        enterprise_yearly: '602ba4da085e3379cb169f6f',
        branch_monthly: '5ea5e90f48020110881362cf',
        branch_yearly: '5ea5e92a48020024842e68bb',
        employee: '58eb82d5675ecb0e60752d04',
        sms: '58f6842714a9ff138069f6d3',
        schnelltest_monthly: '6093dd430d10688e912c6652',
        schnelltest_yearly: '60a4f889dd013ef701b2605f',
        schnelltestBescheinigung: '60867bf5e67f4bca9094bfe9',
        schnelltest2_monthly: '61c05ce0e712b5d2818e5a9e',
        schnelltest2_yearly: '61c05d05e712b5d2818e5cb2',
        schnelltest2Bescheinigung: '61c05d51e712b5d2818e617e',
        schnelltest_archiv: '63fbd90c990e06ac8b9fac67'
      }
    },
    bearer: {
      creditCard: 'CreditCard:Stripe',
      payPal: 'PayPal',
      directDebit: 'Debit:Stripe',
      invoice: 'InvoicePayment',
      none: 'None:None'
    }
  },
  paymentMethods: {
    at: ['creditCard', 'invoice', 'directDebit'],
    de: ['creditCard', 'invoice', 'directDebit'],
    ch: ['creditCard', 'invoice']
  },
  billwerkKey: '%billwerkKey%',
  invoiceFolder: '/api/v2/payment/',
  defaultLanguage: 'de',
  login: './login.html?v=3.81.1',
  settingsForm: require('./settingsFields'),
  cancellation: {
    redirect: 'https://www.termin2go.com'
  },
  dataPrivacyContractUrl: 'https://signnow.com/s/IuITEIzH?address={address}&date={date}&sgn_doc_name=Termin2go%20-%20Auftragsverarbeitung',
  calendar: {
    contextMenu: {
      appointment: true,
      course: true,
      private: true,
      abort: true
    }
  },
  availableLanguages: [
    'de',
    'en'
  ],
  google: {
    maps: 'AIzaSyDxNSCFpgjehf4Ingced5dHALCrUQi061c'
  },
  urls: {
    booking: 'https://booking.termin2go.com'
  }
});
