var openingHoursModule = angular.module('t2g.openingHours', [
    'gettext',
    'ui.router',
    require('t2g.common.services.notificationService').name
]);

module.exports = openingHoursModule;

openingHoursModule.controller('OpeningHoursCtrl', require('./openingHoursCtrl'));
openingHoursModule.config(function($stateProvider) {
    $stateProvider
        .state('private.main.openingHours', {
            url: '/openingHours',
            title: 'Opening hours',
            templateUrl: 'https://cdn.termin2go.com/app/app/modules/openingHours/OpeningHoursView.html?v=3.81.1',
            controller: 'OpeningHoursCtrl',
            resolve: {
                officeResponse: function(OfficeDA) {
                    return OfficeDA.get();
                }
            }
        });
});

